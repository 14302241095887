<template>
  <b-card-actions
      ref="first-card"
      class="list-card"
      @refresh="refreshStop('first-card')"
  >
    <b-row class="mt-50 mb-5">
      <div class="card-header-row" :style="{backgroundColor: headerManagement.backgroundColor}">
        <b-col cols="12" class="d-flex justify-content-between">
          <h4 class="text-white d-inline-block card-title">{{ headerManagement.infoText }}</h4>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="headerManagement.buttonVariant"
              class="row-btn"
              @click="headerManagement.buttonAction()"
          >
            <font-awesome-icon :icon="headerManagement.buttonIcon" class="mr-25"/>
            {{ headerManagement.buttonText }}
          </b-button>
        </b-col>
      </div>
    </b-row>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :variant="headerManagement.buttonVariant"
        class="mobile-row-btn"
        @click="headerManagement.buttonAction()"
    >
      <font-awesome-icon :icon="headerManagement.buttonIcon" class="mr-25"/>
      {{ headerManagement.buttonText }}
    </b-button>
    <hr>
    <div v-if="!isSave && !updateID">
      <b-row>
        <b-col lg="6" md="6" sm="6" cols="12">
          <b-form-group class="mb-md-0 number-of-records" size="sm">
            <b-input-group
                size="sm"
                prepend="Gösterilecek kayıt sayısı"
            >
              <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  @change="perPageChange($event)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="12">
          <b-input-group size="sm" class="search-bar">
            <b-input-group-prepend>
              <b-button
                  variant="outline-primary"
                  size="sm"
                  disabled
                  class="search-input mb-0"
                  :style="{height: width < 768 ? '30px':''}"
              >
                Ara
              </b-button>
            </b-input-group-prepend>
            <b-form-input id="filterInput" v-model="filter" type="search" autocomplete="off"/>
          </b-input-group>
        </b-col>
      </b-row>
      <hr>
      <b-table
          hover
          striped
          responsive
          class="position-relative cari-group-table mt-1"
          :per-page="perPage"
          :items="listGet"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          @row-clicked="goCariDetay"
      >
        <template #cell(firmaUnvan)="data">
          <p v-if="data.item.firmaKodu" v-b-tooltip.hover.top="data.item.firmaUnvan" id="firma-unvan-yetkili">{{
              getFirmaUnvan(data.item.firmaUnvan)
            }}{{ getFirmaUnvanDot(data.item.firmaUnvan) }}</p>
          <b-badge variant="primary" id="firma-unvan-badge" class="align-top yetkili-name"
                   v-if="data.item.adi ||data.item.soyAdi"
          >
            {{ data.item.adi }}
            {{ data.item.soyAdi }}
          </b-badge>
          <b-badge variant="primary" id="firma-unvan-badge" class="align-top yetkili-name ml-50">
            <font-awesome-icon icon="fa-solid fa-phone"/>
            {{ data.item.telefon }}
          </b-badge>
        </template>
        <template #cell(vergiNumarasi)="data">
          <font-awesome-icon icon="address-card"/>
          <strong class="ml-50">
            {{ data.item.vergiNumarasi }}
          </strong>
        </template>
        <template #cell(bakiye)="data">
          <p class="p-0 m-0 d-block font-weight-bold" v-if="data.item.bakiye == 0">0₺</p>
          <p class="p-0 m-0 d-block font-weight-bold" v-if="data.item.bakiye != 0">{{
              Number(data.item.bakiye)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            }} TL</p>
          <p class="p-0 m-0 d-block">
                  <span class="text-success font-weight-bold" v-if="data.item.bakiye > 0"
                  >Tahsil Edilecek</span>
            <span class="text-danger font-weight-bold" v-else-if="data.item.bakiye < 0">Ödenecek</span>
            <span class="text-secondary font-weight-bold" v-else>Bakiye Yok</span>
          </p>
        </template>
        <template #cell(cariKartID)="data">
          <div class="text-right">
            <b-button
                v-b-modal.cari-edit
                class="btn-icon rounded-circle mr-50 pull-up"
                :style="{backgroundColor:$store.state.colors.warningColor+'!important',border:'none'}"
                @click="updateID = data.item.cariKartID,updateCariData = data.item"
            >
              <font-awesome-icon icon="pen"/>
            </b-button>
            <b-button
                class="btn-icon rounded-circle pull-up mr-50"
                :style="{backgroundColor:$store.state.colors.dangerColor+'!important',border:'none'}"
                @click="cariRemove(data.item.cariKartID)"
            >
              <font-awesome-icon icon="trash-can"/>
            </b-button>
            <b-button
                class="btn-icon rounded-circle mr-50 pull-up"
                :style="{backgroundColor:$store.state.colors.successColor+'!important',border:'none'}"
                :to="'/cariEkstre/'+data.item.cariKartID"
            >
              <font-awesome-icon icon="file-contract"/>
            </b-button>
          </div>
        </template>
      </b-table>
      <hr>
      <b-row class="d-flex align-items-center mt-1">
        <b-col cols="6">
          <p class="text-muted">Toplam {{ totalRows || 0 }} Kayıt bulundu</p>
        </b-col>
        <b-col cols="6">
          <b-pagination
              v-model="currentPage"
              @change="currentPageChange($event)"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 float-right"
          />
        </b-col>
      </b-row>
    </div>
    <cari-save v-if="isSave" @saving="saving"/>
    <cari-update v-if="updateID" :cari-data="updateCariData" @updating="updating"/>
  </b-card-actions>
</template>
<script>
import {
  BFormSelect,
  BPagination,
  BTable,
  BFormFile,
  VBModal,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardBody,
  BCardHeader,
  BListGroup,
  BListGroupItem,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
  BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import 'vue-search-select/dist/VueSearchSelect.css'
import store from '@/store'
import router from '@/router'
import axios from 'axios'
import CariSave from '@/views/cari/cariSave.vue'
import CariUpdate from '@/views/cari/cariUpdate.vue'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BFormSelect,
    BPagination,
    BTable,
    CariSave,
    CariUpdate,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    ModelListSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardBody,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      updateCariData: [],
      updateID: null,
      isSave: false,
      fields: [
        {
          key: 'firmaUnvan',
          label: 'FİRMA ÜNVANI',
          sortable: true,
          thStyle: {
            width: '40%',
            backgroundColor: store.state.colors.tableHeaderColor,
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '40%' },
        },
        {
          key: 'vergiNumarasi',
          label: 'TC KİMLİK / VERGİ NO',
          sortable: true,
          thStyle: {
            width: '19%',
            backgroundColor: store.state.colors.tableHeaderColor,
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '19%' },
        },
        {
          key: 'bakiye',
          label: 'BAKİYE',
          sortable: true,
          thStyle: {
            width: '13%',
            backgroundColor: store.state.colors.tableHeaderColor,
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '13%' },
        },
        {
          key: 'cariKartID',
          label: 'İşlemler',
          sortable: false,
          thClass: ['text-right'],
          tdClass: ['text-right', 'p-0'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor,
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
      ],
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100, 200, 500],
      toplamKayit: 1,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    headerManagement() {
      return {
        backgroundColor: this.isSave ? '#35A29F' : this.updateID ? '#E06469' : store.state.colors.headerRowColor,
        infoText: this.isSave ? 'Cari Kayıt' : this.updateID ? `${this.updateCariData.adi || this.updateCariData.faturaUnvan}  Adlı Cari Kart Düzenleniyor` : 'Cari Kartlar',
        buttonText: this.isSave ? 'Cari Listesi' : this.updateID ? 'Cari Listesi' : 'Yeni Cari Kart',
        buttonVariant: this.isSave ? 'warning' : this.updateID ? 'warning' : 'success',
        buttonIcon: this.isSave ? 'chevron-left' : this.updateID ? 'chevron-left' : 'plus',
        buttonAction: () => {
          if (this.isSave) {
            this.isSave = false
          } else if (this.updateID) {
            this.updateID = null
            this.updateCariData = []
          } else {
            this.isSave = true
          }
        },
      }
    },
    listGet: () => store.getters.GET_CARI_LIST,
  },
  watch: {
    filter(newVal) {
      this.currentPage = 1
      this.$refs['first-card'].showLoading = true
      const fd = this.postSchema()
      fd.append('methodName', 'cariListeSearch')
      fd.append('searchKey', newVal || '')
      fd.append('baslangic', 0)
      fd.append('uzunluk', this.perPage)
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_CARI_LIST',
              list: response.cariList,
              action: 1,
            })
            this.toplamKayit = store.getters.GET_CARI_LIST.length
            this.totalRows = response.toplamKayit
          })
          .finally(() => this.$refs['first-card'].showLoading = false)
    },
  },
  created() {
    this.getCariList()
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_CARI_LIST',
      action: 0,
    })
  },
  methods: {
    cariRemove(cariID) {
      const fd = this.postSchema()
      fd.append('methodName', 'cariSil')
      fd.append('cariKartID', cariID)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.getCariList(true)
            }
          })
    },
    getCariList(isTwice = false) {
      const veri = this.currentPage * this.perPage - this.perPage
      const fd = this.postSchema()
      fd.append('methodName', 'cariListeSearch')
      fd.append('searchKey', this.filter || '')
      fd.append('baslangic', veri || 0)
      fd.append('uzunluk', this.perPage)
      axios.post('', fd)
          .then(response => {
            if (response.cariList.length === 0 && isTwice) {
              this.currentPage = 1
              this.getCariList()
            }
            store.commit({
              type: 'SET_CARI_LIST',
              list: response.cariList,
              action: 1,
            })
            this.toplamKayit = store.getters.GET_CARI_LIST.length
            this.totalRows = response.toplamKayit
          })
    },
    currentPageChange(newVal) {
      this.$refs['first-card'].showLoading = true
      const veri = newVal * this.perPage - this.perPage
      const fd = this.postSchema()
      fd.append('methodName', 'cariListeSearch')
      fd.append('searchKey', this.filter || '')
      fd.append('baslangic', veri)
      fd.append('uzunluk', this.perPage)
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_CARI_LIST',
              list: response.cariList,
              action: 1,
            })
            this.toplamKayit = store.getters.GET_CARI_LIST.length
            this.totalRows = response.toplamKayit
          })
          .finally(() => this.$refs['first-card'].showLoading = false)
    },
    perPageChange(newVal) {
      const veri = this.currentPage * newVal - newVal
      this.$refs['first-card'].showLoading = true
      const fd = this.postSchema()
      fd.append('methodName', 'cariListeSearch')
      fd.append('searchKey', this.filter || '')
      fd.append('baslangic', veri)
      fd.append('uzunluk', newVal)
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_CARI_LIST',
              list: response.cariList,
              action: 1,
            })
            this.toplamKayit = store.getters.GET_CARI_LIST.length
            this.totalRows = response.toplamKayit
          })
          .finally(() => this.$refs['first-card'].showLoading = false)
    },
    goCariDetay(item) {
      router.push({
        name: 'cariDetay',
        params: { id: item.cariKartID },
      })
    },
    updating(value) {
      if (value === 1) {
        this.getCariList()
        this.updateID = null
      }
    },
    saving(value) {
      if (value === 1) {
        this.getCariList()
        this.isSave = !this.isSave
      }
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'cari')
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style scoped>

</style>
